import React, { useEffect } from 'react';
import { GalleryType, importAll } from '../helper';
import { ImageList } from '../components/ImageList';
import { Header } from '../components/Header';
import { GalleryFilter } from '../components/GalleryFilter';
import { FocusedImageContext } from '../App';
import { GalleryPagination } from '../components/GalleryPagination';

export const Gallery = () => {
  const { imageList, setImageList } = React.useContext(FocusedImageContext);

  const [galleryType, setGalleryType] = React.useState(GalleryType.PreWedding);
  useEffect(() => {
    let imageListBasedOnGalleryType: string[] = [];
    if (galleryType === GalleryType.PreWedding) {
      const preweddings = importAll(
        require.context('../assets/pre-wedding', false, /\.(png|JPG|jpe?g|svg)$/)
      ) as string[];
      imageListBasedOnGalleryType = preweddings;
    } else if (galleryType === GalleryType.LoveStory) {
      const lovestories2022 = importAll(
        require.context('../assets/2022', false, /\.(png|JPG|jpe?g|svg)$/)
      ) as string[];
      const lovestories2021 = importAll(
        require.context('../assets/2021', false, /\.(png|JPG|jpe?g|svg)$/)
      ) as string[];
      imageListBasedOnGalleryType = [...lovestories2022, ...lovestories2021];
    } else {
      const preweddings = importAll(
        require.context('../assets/pre-wedding', false, /\.(png|JPG|jpe?g|svg)$/)
      ) as string[];
      const lovestories2022 = importAll(
        require.context('../assets/2022', false, /\.(png|JPG|jpe?g|svg)$/)
      ) as string[];
      const lovestories2021 = importAll(
        require.context('../assets/2021', false, /\.(png|JPG|jpe?g|svg)$/)
      ) as string[];
      imageListBasedOnGalleryType = [
        ...preweddings,
        ...lovestories2022,
        ...lovestories2021,
      ];
    }
    setImageList(imageListBasedOnGalleryType);
  }, [galleryType, setImageList]);
  return (
    <>
      <Header />
      <GalleryFilter selectedGallery={galleryType} setSelectedGallery={setGalleryType} />
      <ImageList imageList={imageList} />
      <GalleryPagination
        selectedGallery={galleryType}
        setSelectedGallery={setGalleryType}
      />
    </>
  );
};
