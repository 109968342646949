import React from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';

export const DropdownButton = ({children, label}: {children: React.ReactNode, label: string}) => {
    const wrapperRef = React.useRef<HTMLDivElement| null>(null);
    const [open, setOpen] = React.useState(false);
    document.addEventListener('mousedown', (e) => {
        if(wrapperRef.current && !wrapperRef.current.contains(e.target as Node)){
            setOpen(false);
        }
    });
    return (
        <div ref={wrapperRef} className="relative inline-block text-left">
           <div>
            <button type="button" className="inline-flex w-full justify-center gap-x-1.5 rounded-md hover:bg-gray-200 p-2" id="dropdown-button" aria-expanded={open} aria-haspopup={open} onClick={()=>setOpen(!open)}>
            {label}
            <ArrowDropDown/>
            </button>
        </div>
        {open && (
            <div className="absolute right-0 z-1 mt-1 gap-2 p-1 origin-top-right rounded-md bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex={-1}>
            <div className="flex flex-col py-1" role="none">
                {children}
            </div>
        </div>
        )}
        </div>
    );
}
