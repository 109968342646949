import React from 'react';
import { LanguageContext } from '../App';
import Mail from '@mui/icons-material/Mail';

export const Invitation = () => {
  const { language } = React.useContext(LanguageContext);
  return (
    <div className="m-0 flex flex-col items-center text-center gap-3 md:gap-5 bg-stone-100">
      <h1 className="mt-3 text-lg md:text-xl">
        {language === 'en'
          ? 'Hey there, you got an invitation from us'
          : 'Chào bạn, bạn có thư mời từ chúng tớ'}{' '}
        <span>
          <Mail fontSize={window.screen.width > 1024 ? 'large' : 'medium'} />
        </span>
      </h1>
      <div className="m-auto w-4/5 lg:w-2/5 h-auto">
        <img
          className="m-auto max-w-full h-auto border-2 border-yellow-600"
          src={
            language === 'en'
              ? require('../assets/invitations/en.png')
              : require('../assets/invitations/vn.png')
          }
          alt="invitation"
        />
      </div>
      <h1 className="mx-3">
        {language === 'en'
          ? "If you haven't got the physical invitation letter, please kindly contact us at tramnguyen1601437@gmail.com to let us know that you would like to come. Thank you ♥"
          : 'Nếu bạn chưa nhận được thiệp mời từ chúng tớ, xin hãy cho chúng tớ biết qua email tramnguyen1601437@gmail.com. Cám ơn bạn ♥'}
      </h1>
      <div className="w-full bg-stone-600 text-white text-lg p-5 md:p-8 flex flex-col gap-3 md:gap-5">
        <div className="w-fit self-start">
          <h1>
            {language === 'en' ? 'Map to the venue' : 'Bản đồ đến nơi tổ chức tiệc'}
          </h1>
        </div>
        <div className="m-auto w-full">
          <iframe
            title="google map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.9977820456406!2d106.8507602759346!3d10.963539389196818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174dc39cf288fd1%3A0x50a2c75aa7cd657!2sConference%20Center%20Wedding%20Palace%20Eros!5e0!3m2!1sen!2sfi!4v1729778810646!5m2!1sen!2sfi"
            className="w-full h-[250px] md:h-[450px]"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
