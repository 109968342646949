import React from 'react';
import { LanguageContext } from '../App';
import { Button } from './atoms/Button';
import { GalleryType, galleryTypeToText } from '../helper';
import { ArrowForward } from '@mui/icons-material';

export const GalleryPagination = ({
  selectedGallery,
  setSelectedGallery,
}: {
  selectedGallery: GalleryType;
  setSelectedGallery: (value: GalleryType) => void;
}) => {
  const { language } = React.useContext(LanguageContext);
  const getNextGallery = () => {
    switch (selectedGallery) {
      case GalleryType.PreWedding:
        return GalleryType.LoveStory;
      case GalleryType.LoveStory:
        return GalleryType.All;
      default:
        return GalleryType.PreWedding;
    }
  };
  return (
    <div className="m-3 md:m-5 flex flex-row justify-end">
      <Button
        onClick={() => setSelectedGallery(getNextGallery())}
        className="w-fit-content"
      >
        {language === 'en' ? 'See album ' : 'Xem album '}
        {galleryTypeToText(getNextGallery(), language)}
        <ArrowForward fontSize={window.screen.width > 1024 ? 'medium' : 'small'} />
      </Button>
    </div>
  );
};
