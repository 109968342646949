import React from 'react';
import { FocusedImageContext, LanguageContext } from '../App';
import { importOne } from '../helper';
import { Button } from './atoms/Button';
import { Link } from 'react-router-dom';

export const Header = () => {
  const { language } = React.useContext(LanguageContext);
  const { imageList } = React.useContext(FocusedImageContext);
  return (
    <div className="w-full relative">
      <img
        className="m-auto h-[850px] w-full object-cover object-center opacity-90 hover:opacity-85"
        src={
          imageList[0] ??
          (importOne(
            require.context('../assets/pre-wedding', false, /\.(png|JPG|jpe?g|svg)$/)
          ) as string)
        }
        alt="gallery-demo"
      />
      <div className="absolute bottom-2 left-4 text-white text-2xl md:text-3xl font-bold flex flex-col items-center">
        <h1>Mikael & Tram</h1>
        <p className="font-normal text-xl md:text-2xl">18.01.2025</p>
      </div>
      {window.location.hash.includes('/gallery') ? null : (
        <div className="absolute bottom-2 right-4 text-lg md:text-2xl text-white hover:text-gray-800">
          <Link to="/gallery">
            <Button className="border-2 border-white p-1">
              {language === 'en' ? 'View Gallery' : 'Xem ảnh'}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
