import React from 'react';
import { FocusedImageContext } from '../App';

export const ImageList = ({ imageList }: { imageList: string[] }) => {
  const { setFocusedImage } = React.useContext(FocusedImageContext);

  return (
    <>
      <div
        className={`m-2 mb-0 columns-2 md:columns-3 lg:columns-3 xl:columns-4 2xl:columns-5 gap-1`}
      >
        {imageList.map((src: any) => (
          <img
            className="w-full h-auto mb-1"
            key={src}
            src={src}
            alt={src}
            onClick={() => setFocusedImage(src)}
          />
        ))}
      </div>
    </>
  );
};
