import React from 'react';
import { FocusedImageContext } from '../App';

export const Footer = () => {
  const { focusedImage } = React.useContext(FocusedImageContext);
  return (
    <div
      className={`w-full text-center text-xs text-amber-950 bg-stone-100 p-3 ${focusedImage ? 'opacity-10' : ''}`}
    >
      <h1>From Mikael and Tram with ♥</h1>
    </div>
  );
};
