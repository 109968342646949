export const importAll = (r: __WebpackModuleApi.RequireContext) => r.keys().map(r);

export const importOne = (r: __WebpackModuleApi.RequireContext) => r(r.keys()[0]);

export type Language = 'en' | 'vn';

export enum GalleryType {
  PreWedding = 'pre-wedding',
  LoveStory = 'love-story',
  All = 'all',
}

export const galleryTypeToText = (galleryType: GalleryType, language: Language) => {
  switch (galleryType) {
    case GalleryType.PreWedding:
      return language === 'en' ? 'Pre-wedding' : 'Ảnh cưới';
    case GalleryType.LoveStory:
      return language === 'en' ? 'Daily life' : 'Thường ngày';
    case GalleryType.All:
      return language === 'en' ? 'All' : 'Tất cả';
  }
};
