import React from 'react';
import { ReactComponent as Logo } from '../assets/props/logo.svg';
import { LanguageContext } from '../App';
import { DropdownButton } from './atoms/DropdownButton';
import { Button } from './atoms/Button';
import { Link } from 'react-router-dom';

export const NavBar = () => {
  const { language, setLanguage } = React.useContext(LanguageContext);
  return (
    <>
      <div className="block fixed z-10 left-0 top-0 w-full h-[90px] md:h-[120px] flex flex-row justify-between bg-stone-100">
        <Link to="/">
          <Logo className="w-[90px] md:w-[120px] h-[90px] md:h-[120px] " />
        </Link>
        <div className="flex flex-row gap-3 md:gap-5 mx-3 md:mx-5 items-center font-semi-bold text-amber-950">
          <Link to="/">
            <Button children={language === 'en' ? 'Home' : 'Trang chủ'} />
          </Link>
          <Link to="/gallery">
            <Button children={language === 'en' ? 'Gallery' : 'Ảnh'} />
          </Link>
          <DropdownButton label={language === 'en' ? 'English' : 'Tiếng Việt'}>
            <Button onClick={() => setLanguage('en')} children="English" />
            <Button onClick={() => setLanguage('vn')} children="Tiếng Việt" />
          </DropdownButton>
        </div>
      </div>
      <div className="w-full h-[90px] md:h-[120px]"></div>
    </>
  );
};
