import React from 'react';
import { LanguageContext } from '../App';
import { Button } from './atoms/Button';
import { GalleryType, galleryTypeToText } from '../helper';

export const GalleryFilter = ({
  selectedGallery,
  setSelectedGallery,
}: {
  selectedGallery: GalleryType;
  setSelectedGallery: (value: GalleryType) => void;
}) => {
  const { language } = React.useContext(LanguageContext);
  return (
    <div className="my-3 md:my-5 flex flex-row">
      {Object.values(GalleryType).map((value) => (
        <Button
          key={value}
          onClick={() => setSelectedGallery(value)}
          className={`${selectedGallery === value ? 'text-amber-700 underline underline-offset-8' : ''}`}
        >
          {galleryTypeToText(value, language)}
        </Button>
      ))}
    </div>
  );
};
