import React from 'react';
import { SingleImage } from './components/SingleImage';
import { NavBar } from './components/NavBar';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { Gallery } from './pages/Gallery';
import { Home } from './pages/Home';
import { Footer } from './components/Footer';
import { Language } from './helper';

export const LanguageContext = React.createContext<{language: Language, setLanguage: (value: Language) => void}>({
  language: 'vn',
  setLanguage: () => {}
});

export const FocusedImageContext = React.createContext<{imageList: string[], setImageList: (list: string[]) => void, focusedImage: string | null, setFocusedImage: (value: string | null) => void}>({
  imageList: [],
  setImageList: () => {},
  focusedImage: null,
  setFocusedImage: () => {}
});

const App = () => {
  const [language, setLanguage] = React.useState<Language>('vn');
  const [focusedImage, setFocusedImage] = React.useState<string | null>(null);
  const [imageList, setImageList] = React.useState<string[]>([]);
  const focusImageIndex = focusedImage && !!imageList.length ? imageList.findIndex(v => v===focusedImage) : -1;
  const focusOnPreviousImage = focusImageIndex > 0 ? () => setFocusedImage(imageList[focusImageIndex-1]) : undefined;
  const focusOnNextImage = (focusImageIndex > -1 && focusImageIndex< (imageList.length-1))  ? () => setFocusedImage(imageList[focusImageIndex+1]) : undefined;
  return (
    <LanguageContext.Provider value={{language, setLanguage}}>
    <FocusedImageContext.Provider value={{imageList, setImageList, focusedImage, setFocusedImage}}>
    <div className={`text-gray-500 bg-stone-100 font-serif text-sm md:text-base p-1 ${focusedImage ? 'opacity-10' : ''}`}>
      <Router>
        <header>
          <NavBar/>
        </header>
        <Routes>
        <Route path='/gallery' element={<Gallery/>}/>
        <Route path='/' element={<Home/>}/>
        </Routes>
      </Router>
    </div>
    <div>
    </div>
    {focusedImage && <SingleImage src={focusedImage} onClose={()=>setFocusedImage(null)} onPrevious={focusOnPreviousImage} onNext={focusOnNextImage}/>}
    <Footer/>
    </FocusedImageContext.Provider>
    </LanguageContext.Provider>
  );
}

export default App;
