import React from 'react';
import { Invitation } from '../components/Invitation';
import { Header } from '../components/Header';

export const Home = () => {
  return (
    <>
      <Invitation />
      <Header />
    </>
  );
};
