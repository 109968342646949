import React from 'react';

import { Button } from './atoms/Button';
import { useSwipe } from '../hooks/useSwipe';
import {
  ZoomOut,
  ZoomIn,
  NavigateBefore,
  NavigateNext,
  ArrowBack,
} from '@mui/icons-material';

export const SingleImage = ({
  src,
  onClose,
  onPrevious,
  onNext,
}: {
  src: string;
  onClose: () => void;
  onPrevious?: () => void;
  onNext?: () => void;
}) => {
  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe({
    onSwipeLeft: onNext,
    onSwipeRight: onPrevious,
  });
  const isSmallScreen = window.screen.width <= 768;
  const [imageWidth, setImageWidth] = React.useState<number>(
    window.screen.width > 1024 ? 40 : 50
  );
  return (
    <div
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      className="block fixed z-10 left-0 top-3 overflow-auto w-full h-full flex flex-col"
    >
      <div className="flex justify-start mx-3 my-3">
        <Button className="text-3xl p-2" onClick={onClose}>
          <ArrowBack />
        </Button>
      </div>
      {isSmallScreen ? (
        <div
          className={`m-auto sm:w-full lg:w-3/5 xl:w-2/5 2xl:w-2/5 flex flex-col items-center`}
        >
          <img className="w-full h-auto" src={src} alt={src} />
        </div>
      ) : (
        <div className="m-2 flex flex row gap-1 justify-between items-center">
          <Button
            className="h-fit sm:p-0 p-1"
            onClick={onPrevious}
            disabled={onPrevious === undefined}
          >
            <NavigateBefore fontSize="large" />
          </Button>
          <div
            style={{ width: `${imageWidth}%` }}
            className={`m-auto p-5 flex flex-col items-center`}
          >
            <div className="flex flex row w-fit mb-1">
              <Button
                className="h-fit p-1"
                onClick={() => setImageWidth(imageWidth - 10)}
                disabled={imageWidth === 10}
              >
                <ZoomOut fontSize="medium" />
              </Button>
              <Button
                className="h-fit p-1"
                onClick={() => setImageWidth(imageWidth + 10)}
                disabled={imageWidth === 100}
              >
                <ZoomIn fontSize="medium" />
              </Button>
            </div>
            <img className="m-auto w-full h-auto" src={src} alt={src} />
          </div>
          <Button
            className="h-fit sm:p-0 p-1"
            onClick={onNext}
            disabled={onNext === undefined}
          >
            <NavigateNext fontSize="large" />
          </Button>
        </div>
      )}
    </div>
  );
};
